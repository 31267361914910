.react-sanfona {
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 70px;
}

.react-sanfona-item-title {
    background-color: #fafafa;
    border-top: 1px solid #ccc;
    color: #333;
    padding: 14px;
    text-transform: uppercase;
    transition: background-color .3s;
}

.react-sanfona-item:first-child .react-sanfona-item-title {
    border-top: none;
}

.react-sanfona-item-expanded .react-sanfona-item-title {
    background-color: rgb(39, 164, 254);
    color: white;
}

.title-done-btn {
    display: inline-block;
    float: right;
    color: #000;
}

.react-sanfona-item-body-wrapper {
    color: #666;
    padding: 14px;
    position: relative;
}

.react-sanfona-item-body-wrapper:hover .tooltip {
    opacity: 1;
}

.title-done-btn {
    display: none;
}

.includesAudience {
    background-color: #ff443d;
    color: black;
}

.selected {
    background-color: #ffff00;
    color: black;
}

.notEnoughSeats {
    background-color: #FFC966;
    color: black;
}
