.input-width > div{
    width: 100%;
}
.results{
    overflow: auto;
    max-height: 320px;
    width: 97% !important;

}
.ui.search>.results .result .image{
    border-radius: unset;
    float: left;
}
