.data-grid-container .data-grid .cell {
    text-align: left ;
}

.data-grid-container .data-grid .cell > input {
    outline: none !important;
    border: 2px solid #2185d0;
    text-align: left;
    width: 100%;
    height: 100%;
    background: none;
    display: block;
}