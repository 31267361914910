/*.react-notification-center .r-notifications-icon.active {


}*/


.notification-count {
    font-size: 2.3em;
}

.notification-count_size {
    font-size: 2.5em !important;
    line-height: .6 !important;
}
.r-notification {
    width: 100%;
    height: 450px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transform: translate(-400px, 0);
    transition: all 0.3s ease-in-out;
}

.r-notification.active {
    transform: translate(0, 0);
}

.r-notification .desc {
    padding: 20px;
}

.r-notification button {
    display: block;
    width: 50px;
    height: 30px;
    margin: 4px auto auto;
    background-color: transparent;
    border: none;
    outline: none;
}

.r-notification button:hover {
    cursor: pointer;
}

.r-notification button .back {
    display: block;
    width: 40px;
    height: 4px;
    background-color: #ccc;
    position: relative;
    border-radius: 4px;
}

.r-notification button .back:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid #ccc;
    border-bottom: 7px solid transparent;
    position: absolute;
    top: -5px;
    left: -5px;
}

.r-notifications {
    width: 100%;
    height: 450px;
    z-index: 1;
    overflow: hidden;
    position: relative;
}

.r-notifications ul.rn-ul {
    padding: 0;
}

.r-notifications li.rn-item {
    width: 100%;
    padding: 10px 0;
    list-style: none;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #f5f5f5;
}

.r-notifications li.rn-item:before {
    display: none;
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 50%;
    left: 8px;
    background-color: #666;
    border-radius: 50%;
}

.r-notifications li.rn-item:hover {
    background-color: #fcfcfc;
    box-shadow: inset 0 0 2px #f2f2f2;
}

.r-notifications li.rn-item.new:before {
    display: block;
}

.r-notifications li.rn-item:last-child {
    border: none;
}

.r-notifications li.rn-item .short-desc {
    width: 80%;
    margin: 5px auto;
}

.r-notifications li.rn-item .short-desc .notification-tag {
    color: white;
    border-radius: 16px;
    padding: 3px 7px;
    font-size: 11px;
    text-transform: uppercase;
    margin-right: 4px;
    line-height: 20px;
}

.r-notifications li.rn-item .short-desc .notification-tag.info {
    background-color: #58abc3;
}

.r-notifications li.rn-item .short-desc .notification-tag.success {
    background-color: #60bb71;
}

.r-notifications li.rn-item .short-desc .notification-tag.warning {
    background-color: #f7a336;
}

.r-notifications li.rn-item .short-desc .notification-tag.danger {
    background-color: #db6a64;
}

.r-notifications li.rn-item .short-desc:hover .title {
    text-decoration: underline;
}

.r-notifications li.rn-item .short-desc .date {
    display: block;
    font-size: 10px;
    color: #999;
    clear: both;
    margin-top: 5px;
}

.react-notification-center {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 99;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.react-notification-center *,
.react-notification-center *:before,
.react-notification-center *:after {
    box-sizing: border-box;
}

/*.react-notification-center .r-notifications-icon {
    min-width: 50px;
    min-height: 50px;
    text-align: center;
    line-height: 33px;
    position: absolute;
    z-index: 0;
    font-size: 10px;
    cursor: pointer;
    left: 6px;
    top: 3px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}*/




/*.react-notification-center .r-notifications-icon:hover {
    transform: scale(1.1);
}

.react-notification-center .r-notifications-icon.pulse {
    animation-name: pulse_animation;
    animation-duration: 300ms;
    animation-iteration-count: 2;
    animation-timing-function: linear;
    -webkit-animation-name: webkit_pulse_animation;
    -webkit-animation-duration: 300ms;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-timing-function: linear;
}*/

.react-notification-center .rn-header {
    width: 100%;
    height: 50px;
    background-color: #fcfcfc;
    border-bottom: 1px solid #f0f0f0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    overflow: hidden;
}

.react-notification-center .rn-header h4 {
    width: 100%;
    padding: 0 20px;
    margin: 0;
    text-align: center;
    line-height: 50px;
    color: #333;
}

.react-notification-center .rn-content {
    width: 100%;
    height: 360px;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;
    font-size: 14px;
}

.react-notification-center .rn-content .no-rn {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #999;
    line-height: 250px;
    overflow: hidden;
}

.react-notification-center .rn-footer {
    width: 100%;
    height: 40px;
    background-color: #fcfcfc;
    border-top: 1px solid #f0f0f0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    overflow: hidden;
}

.react-notification-center .rr-wrapper {
    box-shadow: 3px 3px 25px #dbdbdb;
    border-radius: 3px;
    width: 350px;
    height: 450px;
    position: absolute;
    z-index: 1;
}

@media (min-width: 1100px) {
    .react-notification-center .rr-wrapper.left {
        left: -15vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 16vw;
    }
}

@media (min-width: 768px) {
    .react-notification-center .rr-wrapper.left {
        left: -20vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 20vw;
    }
}

@media (max-width: 992px) {
    .react-notification-center .rr-wrapper.left {
        left: -20vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 21.6vw;
    }
}

@media (min-width: 992px) {
    .react-notification-center .rr-wrapper.left {
        left: -15vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 16.2vw;
    }
}

@media (max-width: 768px) {
    .react-notification-center .rr-wrapper.left {
        left: -25vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 28vw;
    }
}

@media (min-width: 500px) {
    .react-notification-center .rr-wrapper.left {
        left: -15vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 17.5vw;
    }
}

@media (min-width: 760px) {
    .react-notification-center .rr-wrapper.left {
        left: -20.2vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 21.8vw;
    }
}

@media (min-width: 1000px) {
    .react-notification-center .rr-wrapper.left {
        left: -20.2vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 21.3vw;
    }
}

@media (max-width: 1000px) {
    .react-notification-center .rr-wrapper.left {
        left: -20.2vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 21.3vw;
    }
}

@media (min-width: 1100px) {

    .react-notification-center .rr-wrapper:before {
        left: 21.4vw;
    }
}

@media (max-width: 370px) {
    .react-notification-center .rr-wrapper {
        width: 300px;
    }

    .react-notification-center .rr-wrapper.left {
        left: -32vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 35.5vw;
    }
}

@media (max-width: 400px) {

    .react-notification-center .rr-wrapper.left {
        left: -30vw;
    }

    .react-notification-center .rr-wrapper:before {
        left: 33.5vw;
    }
}

.react-notification-center .rr-wrapper.left {
    top: 45px;
}

.react-notification-center .rr-wrapper:before {
    content: '';
    width: 0;
    height: 0;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #fcfcfc;
    position: absolute;
    top: -13px;
}

.react-notification-center .rr-wrapper .notification-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 3px;
}

.react-notification-center.light-theme .notification-box {
    background-color: white;
}

.react-notification-center.light-theme .notification-list .header {
    border-bottom: 1px solid #f0f0f0;
}

.react-notification-center.light-theme .notification-list .header h4 {
    color: #666;
}

.react-notification-center.light-theme .notification-list .contents li.item {
    border-bottom: 1px solid #f9f9f9;
}

.react-notification-center.light-theme .notification-list .contents li.item .short-desc {
    color: #444;
}

.react-notification-center.light-theme .notification-list .footer {
    border-top: 1px solid #f0f0f0;
}

@keyframes pulse_animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.mr-3 {
    margin-right: 3em;
}
[data-notifications] {
    position: relative;
}

/* append the notification badge after it */
[data-notifications]:after {

    /* the burger */
    content: attr(data-notifications);

    /* the fries */
    position: absolute;
    background: red;
    border-radius: 50%;
    display: inline-block;
    padding: 0.3em;
    color: #f2f2f2;
    right: -15px;
    top: -15px;
}



.bell-animation{
    animation: ring 4s .4s ease-in-out infinite;
    transform-origin: 50% 4px;
}


@keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}