.body-container {
    display: flex;
    min-height: 100vh;
    min-width: 100vw;
    flex-direction: row;
    margin: 0;
    font-size: 13px;
}

nav {
    background: #D7E8D4;
    flex: 0 0 220px;
}

.layout-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

main {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 75vh;
}

main > article  {
    flex: 1;
}
main > aside{
    flex: 0 0 250px;
}

.footer {
    width: 100%;
    overflow-x: hidden;
    background: none repeat scroll 0 0 #fff;
    border-top: 1px solid #e7eaec;
    padding: 10px 20px 5px 20px;
}

@media (max-width: 768px) {
    nav, footer {
        display: none;
    }

    .ui.menu:not(.vertical) .right.item,
    .ui.menu:not(.vertical) .right.menu {
        margin-left: inherit !important;
    }
}

img.img-circle {
    width: 32px;
    height: 32px;
}

.img-circle {
    border-radius: 50%;
}

.d-initial {
    display: initial;
}

.sidebar {
    background-color: #2f4050;
    color: #676a6c;
    display: block;
}

.navigation {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navigation ol, .navigation ul {
    margin-top: 0;
    margin-bottom: 10px;
}

.navigation > li.active {
    border-left: 4px solid #19aa8d;
    background: #293846;

}

.navigation > li {
    position: relative;
    display: block;

}

.navigation > li.active > a {
    color: #ffffff !important;
}

.navigation > li > a {
    padding: 14px 20px 14px 25px;
}

.navigation > li > a {
    color: #a7b1c2;
    position: relative;
    display: block;
    padding: 10px 15px;
}

.navigation a {
    cursor: pointer;
    color: #a7b1c2;
    font-weight: 600;
    text-decoration: none;
}

.nav-second-level li, .nav-third-level li {
    border-bottom: none !important;
    width: 100%;
}

.nav-second-level li a {
    padding: 7px 10px 7px 52px;
    display: block;
    width: 100%;
}

.nav-second-level li.active a {
    color: white;
}

.navigation li a:hover, .navigation li a:focus {
    background-color: #293846;
    color: white;
}
