.left {
    float: left;
}

.canDrop {
    background-color: #FF2F2F !important;
    color: black;
}


.enough {
    background-color: #FFC04C !important;
}

.load-border tr {
    border: 2px solid black;
}

.c-trash {
    padding: 1em;
    border: 2px solid grey;
}

.hover {
    background-color: rgb(130, 205, 255) !important;
}

.mt-1 {
    margin-top: 1em;
}

.lesson-border {
    border: 1px solid #4C4C4C;
    border-collapse: collapse;
}

table.cell-border {
    border-collapse: collapse;
}

table.cell-border th,
table.cell-border td {
    border: 2px solid black;
    border-collapse: collapse;
}

table.lessons td {
    height: 5vh;
}

table.cell-border th {
    text-align: center;
}

div.table-load {
    max-height: 80vh;
    overflow-y: auto;
}
div.lessons-table {
    max-height: 60vh;
    overflow: auto;
}

.error {
    background-color: #ff001a !important;
}



